<template lang="pug">
om-modal(name="rename-domain" width="420px" @beforeOpen="beforeOpen" @opened="opened" headerBorder)
  template(slot="modal-header")
    .row
      .col
        om-heading(h5) {{ $t('renameDomain') }}
    .brand-modal-action-icon.cursor-pointer(@click="$modal.hide('rename-domain')")
      close-icon(:width="12" :height="12" color="#AAB1C1")
  template(slot="modal-body")
    input#domainInput.form-control.form-control-lg(
      @keyup.enter="rename"
      :class="{ 'is-invalid': $v.domain.$error }"
      type="text"
      :placeholder="$t('newNamePlaceholder')"
      v-model.trim="domain"
      aria-label="$t('newNamePlaceholder')"
    )
  template(slot="modal-footer")
    .d-flex.justify-content-end
      om-button(primary @click="rename") {{ $t('apply') }}
</template>

<script>
  import { get as _get } from 'lodash-es';
  import RENAME_DOMAIN from '@/graphql/RenameDomain.gql';
  import { validateDomain, cleanDomain } from '@/util';

  export default {
    data() {
      return {
        domain: '',
        alreadyAdded: [],
      };
    },

    validations: {
      domain: {
        isCool(v) {
          return validateDomain(v);
        },
      },
    },

    methods: {
      beforeOpen(event) {
        this.domainId = event.params.domainId;
        this.domain = event.params.domain;
        this.alreadyAdded = _get(event, 'params.alreadyAdded', []);
      },

      opened() {
        document.getElementById('domainInput').focus();
      },

      async rename() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }

        const domainWithoutProtocol = cleanDomain(this.domain);

        if (this.alreadyAdded.includes(domainWithoutProtocol)) {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.renameDomainAlreadyExists'),
          });
          return;
        }

        const {
          data: { renameDomain },
        } = await this.$apollo.mutate({
          mutation: RENAME_DOMAIN,
          variables: {
            domainId: this.domainId,
            domain: domainWithoutProtocol,
          },
        });

        if (renameDomain) {
          this.$modal.hide('rename-domain');
          this.$notify({
            type: 'success',
            text: this.$t('notifications.renameSuccess'),
          });
          this.$emit('refetch');
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('notifications.renameError'),
          });
        }
      },
    },
  };
</script>
